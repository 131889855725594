@import "core";
a {
    text-decoration-thickness: from-font;
    transition: 400ms color, 400ms background, 400ms border-color, 400ms box-shadow, 400ms background-size;
}
ol, ul, dl, p {
    margin: 0;
    padding: 0;
}
body {
    color: sc(b, 0.9);
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
.width-full, .ant-select-single {
    width: 100%;
}
.width-half {
    width: 50%;
}
.ant-form-item-explain-error {
    font: 500 f(13, 17) $f;
    color: c(danger);
    min-height: rem(17);
    &:before {
        content: '';
        display: block;
        width: 100%;
        height: rem(6);
    }
}
.ant-form-item {
    margin: 0 !important;
    .ant-form-item-extra {
        margin-top: rem(6);
        color: sc(b, 0.4);
        font: 400 f(13, 17) $f;
        min-height: rem(17);
        a {
            color: sc(b, 0.4);
            &:after, &:before {
                bottom: 0;
            }
            @include hover {
                color: sc(b, 0.7);
            }
        }
    }
    .ant-form-item-control-input-content {
        > label {
            > .ant-form-item-extra {
                margin: 0;
            }
        }
    }
}
.ant-table-cell {
    vertical-align: middle;
    font-size: rem(13);
}
.ant-upload-wrapper {
    width: 100%;
    .ant-upload-drag {
        background: #f3f3f5;
        border-color: #E8E8E8;
        border-style: dashed;
        border-radius: rem(16);
        transition: 300ms color, 300ms border-color, 400ms height, 300ms background-color;
        .ant-upload-btn {
            padding: rem(24) 0;
            .ant-upload-drag-container {
                button {
                    margin: auto;
                }
                p {
                    margin: rem(16) 0 0;
                    color: c(tertiary, 0.3);
                    text-align: center;
                    font: f(13, 20) $f;
                }
            }
        }
    }
}
.ant-table-row {
    $borderDarken: 5;
    $hover: lighten(#e1e1ec, 8);
    $hoverBorder: darken($hover, $borderDarken);
    $selected: #f2fdfa;
    $selectedBorder: darken($selected, $borderDarken);
    $selectedHover: darken($selected, 2);
    $selectedBorderHover: darken($selectedHover, $borderDarken);
    .ant-table-cell-row-hover {
        background: $hover !important;
        --avatar-outline-color: #{$hover};
        border-color: $hoverBorder !important;
    }
    &:has(+ .ant-table-row .ant-table-cell-row-hover) {
        .ant-table-cell {
            border-color: $hoverBorder !important;
        }
    }
    &:has(+ .ant-table-row-selected) {
        .ant-table-cell {
            border-color: $selectedBorder !important;
        }
    }
    &:has(+ .ant-table-row-selected .ant-table-cell-row-hover) {
        .ant-table-cell {
            border-color: $selectedBorderHover !important;
        }
    }
    &.ant-table-row-selected {
        .ant-table-cell {
            background: $selected !important;
            --avatar-outline-color: #{$selected};
            border-color: $selectedBorder !important;
            $n1: b;
            $n2: hero;
            &:not(.ant-table-selection-column) {
                --#{$n1}-sc: #{rgb(2, 68, 52)};
                --#{$n1}-sc-rgb: 2, 68, 52;
            }
            &.ant-table-cell-row-hover {
                background: $selectedHover !important;
                --avatar-outline-color: #{$selectedHover};
                border-color: $selectedBorderHover !important;
            }
        }
    }
}
.ant-select-dropdown, .ant-dropdown .ant-dropdown-menu, .ant-dropdown-menu-submenu .ant-dropdown-menu {
    padding: rem(6);
    gap: rem(4);
    border-radius: rem(12);
    grid-auto-columns: 100%;
    min-width: rem(160);
    &:not(.ant-select-dropdown-hidden) {
        display: grid;
        pointer-events: all;
    }
    .ant-dropdown-menu-item-divider {
        margin: 0;
    }
    .ant-dropdown-menu-item, .ant-select-item, .ant-dropdown-menu-submenu-title {
        gap: rem(8);
        height: rem(42);
        padding: 0 rem(14);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: color 400ms, background 400ms;
        border-radius: rem(8);
        @include hover {
            background: c(neutral-3);
        }
        &.route-active {
            color: c(hero-hover);
        }
        &:has(.button-c:only-child) {
            padding: 0;
            background: none;
        }
        .button-c {
            &.a-fill {
                color: sc(hero);
            }
        }
        a {
            &:empty {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .ant-dropdown-menu-item-icon {
        margin: 0 0 0 rem(-4);
    }
}
.ant-form {
    .ant-divider-horizontal {
        margin: 0;
    }
}
.ant-divider {
    border-color: sc(b, 0.07) !important;
}
.ant-form-item {
    .ant-form-item-control-input {
        min-height: inherit;
    }
}
.ant-upload-list-picture-card {
    display: flex;
    flex-wrap: wrap;
    gap: rem(8);
    &:before {
        content: none !important;
    }
    > * {
        margin: 0 !important;
    }
    .ant-upload-select {
        p {
            margin: rem(8) 0 0;
            color: sc(b, 0.45);
            text-align: center;
            font: f(14) $f;
        }
    }
}
.ant-upload-list-item-actions {
    &, > a {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.ant-picker-header-view {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-picker-header {
    * {
        vertical-align: middle;
    }
}
.ant-space-compact {
    position: relative;
    z-index: 0;
    align-items: flex-end;
    > .ant-color-picker-trigger {
        &:last-child {
            position: relative;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: rem(-1);
            @include hover {
                z-index: 10;
            }
        }
    }
}
.ant-avatar {
    background: #f3f3f5;
    color: #535775;
    &, .ant-avatar-string {
        font-weight: 600;
    }
}
.ant-popover {
    .ant-popover-inner {
        border-radius: rem(14);
    }
}
.ant-dropdown-menu-submenu-popup {
    &:not(.ant-dropdown-menu-submenu-hidden) {
        pointer-events: all;
    }
}
.ant-user-dropdown {
    top: auto !important;
    bottom: rem(24) !important;
}
.ant-modal {
    .ant-modal-content {
        $padding: 8;
        padding: rem($padding);
        border-radius: rem(16);
        img {
            width: auto;
            height: auto;
            border-radius: rem(10);
            max-width: calc(100vw - #{rem($padding * 4)});
            max-height: calc(100vh - #{rem($padding * 4)});
        }
        .ant-modal-close {
            background: c(b);
            border-radius: rem(6);
        }
    }
}
.ant-tooltip {
    --antd-arrow-background-color: #3A3E44;
    .ant-tooltip-inner {
        background: var(--antd-arrow-background-color);
        border-radius: rem(8);
        color: #F7F7F7;
        font: 600 f(13) $f;
        padding: rem(16);
    }
}
.ant-tag {
    font: 600 f(11) $f;
    height: rem(20);
    padding: 0 rem(8);
    border-radius: rem(22);
    letter-spacing: rem(0.1);
    display: flex;
    align-items: center;
    .ant-tag-close-icon {
        color: inherit !important;
    }
}
